import { useState, useEffect } from 'react';
import {
    Container,
    Box,
    Typography,
    TextField,
    Button,
    CircularProgress,
} from '@mui/material';
import '@fontsource/roboto';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { supabase } from '../Services/SupabaseClient';
import { showSuccessToast, showErrorToast } from '../Hooks/Toastutils';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const accessToken = searchParams.get('access_token');
    const location = useLocation();
    const [validToken, setValidToken] = useState(false);  // Track token validity

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');  // Assuming token is passed in the URL

        if (!token) {
            setError('Invalid reset link');
            navigate('/');  // Redirect to login if no token is found
            return;
        }

        // Validate token
        const validateToken = async () => {
            try {
                const { data, error } = await supabase.auth.api.getUserByCookie(token);
                if (error || !data) {
                    setError('Invalid or expired token');
                    setValidToken(false);  // Invalid token, set validToken to false
                    navigate('/login');  // Redirect to login
                } else {
                    setValidToken(true);  // Valid token, set validToken to true
                }
            } catch (err) {
                setError('An error occurred while validating the token');
                showErrorToast(err.message);
                setValidToken(false);  // On error, set validToken to false
                navigate('/login');  // Redirect to login
            }
        };

        validateToken();
    }, [location.search, navigate]);

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setLoading(true);

        if (!password || !confirmPassword) {
            setError('Please fill in both fields.');
            setLoading(false);
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            setLoading(false);
            return;
        }

        try {
            const { error } = await supabase.auth.updateUser({
                password,
                access_token: accessToken,
            });
            if (error) {
                throw error;
            }
            setSuccess('Password updated successfully!');
            showSuccessToast('Password updated successfully! Redirecting...');
            setTimeout(() => {
                navigate('/');  // Redirect to login page after success
            }, 2000);
        } catch (err) {
            setError(err.message);
            showErrorToast(err.message);
        } finally {
            setLoading(false);
        }
    };

    if (!validToken) {
        return (
            <Container
                maxWidth="sm"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                    backgroundColor: '#121212',
                    padding: 2,
                    fontFamily: 'Roboto, sans-serif',
                }}
            >
                <Box
                    sx={{
                        backgroundColor: 'rgba(40, 40, 40, 0.9)',
                        borderRadius: 2,
                        padding: 4,
                        boxShadow: 3,
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        component="h1"
                        variant="h5"
                        sx={{ mb: 3, color: '#fff', fontFamily: 'Roboto, sans-serif' }}
                    >
                        Invalid Reset Link
                    </Typography>
                    <Typography color="error" sx={{ mt: 2, fontFamily: 'Roboto, sans-serif' }}>
                        The password reset token is invalid or has expired. Please try again.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            mt: 3,
                            backgroundColor: '#1976d2',
                            fontFamily: 'Roboto, sans-serif',
                            '&:hover': {
                                backgroundColor: '#1565c0',
                            },
                        }}
                        onClick={() => navigate('/')}
                    >
                        Go Back to Login
                    </Button>
                </Box>
            </Container>
        );
    }

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: '#121212',
                padding: 2,
                fontFamily: 'Roboto, sans-serif',
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'rgba(40, 40, 40, 0.9)',
                    borderRadius: 2,
                    padding: 4,
                    boxShadow: 3,
                    textAlign: 'center',
                }}
            >
                <Typography
                    component="h1"
                    variant="h5"
                    sx={{ mb: 3, color: '#fff', fontFamily: 'Roboto, sans-serif' }}
                >
                    Reset Password
                </Typography>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="New Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputLabelProps={{
                            style: { color: '#bbb', fontFamily: 'Roboto, sans-serif' },
                        }}
                        InputProps={{
                            style: { color: '#fff', fontFamily: 'Roboto, sans-serif' },
                        }}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Confirm Password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        InputLabelProps={{
                            style: { color: '#bbb', fontFamily: 'Roboto, sans-serif' },
                        }}
                        InputProps={{
                            style: { color: '#fff', fontFamily: 'Roboto, sans-serif' },
                        }}
                    />
                    <Button
                        type="submit"
                        onClick={handlePasswordReset}
                        fullWidth
                        variant="contained"
                        sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: '#af12b1',
                            fontFamily: 'Roboto, sans-serif',
                            '&:hover': {
                                backgroundColor: '#d018d2',
                            },
                        }}
                    >
                        {loading ? <CircularProgress color="inherit" size={24} /> : 'Reset Password'}
                    </Button>
                    {error && (
                        <Typography color="error" sx={{ mt: 2, fontFamily: 'Roboto, sans-serif' }}>
                            {error}
                        </Typography>
                    )}
                    {success && (
                        <Typography
                            sx={{ mt: 2, fontFamily: 'Roboto, sans-serif', color: '#4caf50' }}
                        >
                            {success}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Container>
    );
};

export default ResetPassword;