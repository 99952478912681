import React, { useState, useEffect } from "react";
import { FaCog, FaBars, FaTimes, FaSignOutAlt } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { supabase } from "./Services/SupabaseClient";

const Download = () => {
  const [companyName, setCompanyName] = useState("");
  const [productId, setProductId] = useState("");
  const [template, setTemplate] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [templateOptions, setTemplateOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [productIds, setProductIds] = useState([]); // State to store product IDs
  const [suggestions, setSuggestions] = useState([]);
  const [productName, setProductName] = useState(""); // Add productName state

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTemplateOptions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URI}/elixir/templates`,
          {
            method: "GET",
            headers: { Accept: "application/json" },
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.templates && Array.isArray(data.templates)) {
            setTemplateOptions(data.templates);
          } else {
            console.error("Unexpected data format for template options:", data);
          }
        } else {
          console.error(
            "Error fetching template options:",
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error fetching template options:", error);
      }
    };

    const fetchProductIds = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URI}/elixir/fetch_product`,
          {
            method: "GET",
            headers: { Accept: "application/json" },
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.data && Array.isArray(data.data)) {
            // Set the full product objects, not just IDs
            setProductIds(data.data); // Store the entire array of products
          } else {
            console.error("Unexpected data format for product IDs:", data);
          }
        } else {
          console.error("Error fetching product IDs:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching product IDs:", error);
      }
    };

    fetchTemplateOptions();
    fetchProductIds(); // Fetch product data when the component mounts
  }, []);

  const handleProductIdChange = (e) => {
    const value = e.target.value;
    setProductId(value);

    // Filter suggestions based on the product_id field
    if (value) {
      const filteredSuggestions = productIds
        .filter((option) =>
          option.product_id.toLowerCase().includes(value.toLowerCase())
        )
        .map((option) => option.product_id); // Only map product_id for suggestions
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]); // Clear suggestions if input is empty
    }
  };

  const handleSuggestionSelect = (suggestion) => {
    setProductId(suggestion); // Set the product ID to the selected suggestion
    setSuggestions([]); // Clear suggestions after selection

    // Find the corresponding product object
    const selectedProduct = productIds.find(
      (item) => item.product_id === suggestion
    );
    if (selectedProduct) {
      setProductName(selectedProduct.product_name); // Set the product name from the selected product
    }
  };

  const handleGenerateDocument = async (e) => {
    e.preventDefault();
    setLoading(true);

    const bodyData = {
      company_name: companyName,
      product_id: productId,
      template_name: template,
      customer_name: template === "CUSTOMER SEB" ? customerName : "",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URI}/elixir/generate_document`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        }
      );

      if (!response.ok) {
        const errorMessage = await response.json(); // Read error message from the response
        throw new Error(`${errorMessage[`detail`]}`);
      }

      const blob = await response.blob(); // Get the PDF as a blob
      const url = URL.createObjectURL(blob); // Create a URL for the PDF blob

      // Optional: Set the PDF URL for display in an iframe if necessary
      setPdfUrl(url); // This allows you to display the PDF in an iframe without downloading

      toast.success("Document generated successfully!");
    } catch (error) {
      console.error("Error:", error);
      toast.info(`${error.message}`);
    } finally {
      setLoading(false); // Stop the loading spinner
    }
  };

  const handleBackadmin = () => {
    navigate("/database");
  }

  const handleSignout = async () => {
    try {
      await supabase.auth.signOut(); // Sign out the current user from Supabase
      navigate('/'); // Redirect to home or login page after sign-out
    } catch (error) {
      console.error("Error signing out:", error.message); // Log any sign-out errors
    }
  };

  return (
    <div className="relative flex min-h-screen bg-gradient-to-r from-gray-800 via-gray-900 to-black">
      {/* Header */}
      <div className="absolute top-0 left-0 right-0 bg-gradient-to-r from-gray-900 to-gray-800 p-4 flex justify-between items-center shadow-lg z-10">
        <h1 className="text-white text-xl font-bold">Pharma-App(Beta)</h1>
        <div className="relative flex items-center space-x-4">
          <button
            onClick={handleBackadmin}
            className="outline-dotted outline-blue-600 hover:bg-blue-600 hover:outline-white ease-in-out transition-all duration-500 text-white p-2">
            Admin
          </button>
          <FaCog
            className="text-white text-2xl cursor-pointer hover:text-gray-400 transition-colors"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          />
          {dropdownOpen && (
            <div className="absolute top-10 right-0 min-w-[200px] bg-gray-800 border border-gray-700 rounded-lg shadow-lg p-2">
              <button
                type="button"
                className="flex items-center text-red-500 p-2 hover:bg-gray-700 w-full text-left rounded-lg transition-colors"
                onClick={handleSignout}
              >
                <FaSignOutAlt className="mr-2" /> Sign Out
              </button>
            </div>
          )}
          <FaBars
            className="text-white text-2xl cursor-pointer md:hidden"
            onClick={() => setSidebarOpen(true)}
          />
        </div>
      </div>

      {/* Sidebar */}
      <div
        className={`fixed inset-0 bg-gray-800 bg-opacity-80 z-20 ${sidebarOpen ? "top-0" : "-top-full"
          } md:top-0 md:w-1/4 md:relative md:bg-gradient-to-b from-gray-800 to-gray-900 text-white h-screen p-6`}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-center">Filters</h2>
          <FaTimes
            className="text-white text-2xl cursor-pointer md:hidden"
            onClick={() => setSidebarOpen(false)}
          />
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <label htmlFor="company-name" className="block text-gray-300 mb-2">
              Company Name
            </label>
            <select
              id="company-name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className="w-full p-2 border border-gray-700 rounded bg-gray-900 text-gray-200"
            >
              <option value="" disabled>
                Select a company
              </option>
              <option value="EI">Enzyme Innovation</option>
              <option value="SEB">Specialty Enzymes & Probiotics</option>
            </select>
          </div>

          <div className="relative">
            {" "}
            {/* Add relative positioning to the parent div */}
            <label htmlFor="product-id" className="block text-gray-300 mb-2">
              Product ID
            </label>
            <input
              type="text"
              id="product-id"
              value={productId}
              onChange={handleProductIdChange}
              className="w-full p-2 border border-gray-700 rounded bg-gray-900 text-gray-200"
            />
            {suggestions.length > 0 && (
              <ul className="absolute z-10 bg-gray-900 border border-gray-700 rounded mt-1 w-full">
                {" "}
                {/* Change to absolute */}
                {suggestions.map((suggestion) => (
                  <li
                    key={suggestion}
                    className="p-2 cursor-pointer hover:bg-gray-700"
                    onClick={() => handleSuggestionSelect(suggestion)}
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div>
            <label htmlFor="product-name" className="block text-gray-300 mb-2">
              Product Name
            </label>
            <input
              type="text"
              id="product-name"
              value={productName} // Bind the input to productName state
              readOnly // Make the input non-editable
              className="w-full p-2 border border-gray-700 rounded bg-gray-900 text-gray-400 cursor-not-allowed"
            />
          </div>

          <div>
            <label htmlFor="template" className="block text-gray-300 mb-2">
              Template
            </label>
            <select
              id="template"
              value={template}
              onChange={(e) => setTemplate(e.target.value)}
              className="w-full p-2 border border-gray-700 rounded bg-gray-900 text-gray-200"
            >
              <option value="" disabled>
                Select a template
              </option>
              {templateOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          {template === "CUSTOMER SEB" && (
            <div>
              <label
                htmlFor="customer-name"
                className="block text-gray-300 mb-2"
              >
                Customer Name
              </label>
              <input
                type="text"
                id="customer-name"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                className="w-full p-2 border border-gray-700 rounded bg-gray-900 text-gray-200"
              />
            </div>
          )}

          <button
            onClick={handleGenerateDocument}
            className="bg-blue-600 text-white p-2 rounded hover:bg-blue-500 transition-colors"
          >
            {loading ? (
              <ClipLoader size={20} color="#ffffff" />
            ) : (
              "Generate PDF"
            )}
          </button>
        </div>
      </div>

      {/* PDF Viewer */}
      <div className="flex-1 p-6">
        {pdfUrl && (
          <iframe
            src={pdfUrl}
            title="PDF Document"
            className="mt-10 w-full h-[calc(90vh-2rem)] border-none"
          />
        )}
      </div>
      <ToastContainer theme="dark" />
    </div>
  );
};

export default Download;
