import { useState } from 'react';
import {
    Container,
    CircularProgress,
    Grid,
    Box,
    Typography,
    TextField,
    Button,
} from '@mui/material';
import '@fontsource/roboto'; // Import Roboto font
import RegisterImage from '../../img/register.svg'; // Replace with your illustration path
import { useNavigate } from 'react-router-dom';
import { supabase } from '../Services/SupabaseClient'; // Import Supabase client
import { showSuccessToast, showErrorToast } from '../Hooks/Toastutils';

const RegisterPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        if (!email || !password || !confirmPassword) {
            setError('Please fill in all fields.');
            setLoading(false);
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            setLoading(false);
            return;
        }

        try {
            const { error: authError } = await supabase.auth.signUp({
                email,
                password,
            });

            if (authError) {
                throw authError;
            }
            showSuccessToast('Confirmation Mail Sent to Registered Email');
            setTimeout(() => {
                navigate('/');  // Redirect to login page after success
            }, 2000);

        } catch (err) {
            setError(err.message);
            showErrorToast(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleLogin = () => {
        navigate('/');
    };

    return (
        <Container
            maxWidth="md"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: '#121212',
                padding: 2,
                fontFamily: 'Roboto, sans-serif',
            }}
        >
            <Grid
                container
                spacing={4}
                sx={{
                    backgroundColor: 'rgba(40, 40, 40, 0.9)',
                    borderRadius: 2,
                    boxShadow: 3,
                }}
            >
                {/* Left Column: Illustration */}
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 3,
                        borderRight: { md: '1px solid rgba(255, 255, 255, 0.1)' },
                    }}
                >
                    <Box
                        component="img"
                        src={RegisterImage} // Replace with your illustration path
                        alt="Illustration"
                        sx={{
                            width: '100%',
                            maxWidth: 300,
                            borderRadius: 2,
                        }}
                    />
                    <Box
                        sx={{
                            color: '#bbb',
                            fontSize: '0.875rem',
                            textAlign: 'center',
                            marginTop: 2,
                        }}
                    >
                        <Typography variant="body2" fontFamily="Roboto, sans-serif">
                            &copy; {new Date().getFullYear()} TheCodewise.com. All rights reserved.
                        </Typography>
                    </Box>
                </Grid>

                {/* Right Column: Register Form */}
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 3,
                    }}
                >
                    <Typography
                        component="h1"
                        variant="h5"
                        sx={{ mb: 3, color: '#fff', fontFamily: 'Roboto, sans-serif' }}
                    >
                        Register
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 1, width: '100%' }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="email"
                            autoFocus
                            InputLabelProps={{
                                style: { color: '#bbb', fontFamily: 'Roboto, sans-serif' },
                            }}
                            InputProps={{
                                style: { color: '#fff', fontFamily: 'Roboto, sans-serif' },
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            id="password"
                            autoComplete="new-password"
                            InputLabelProps={{
                                style: { color: '#bbb', fontFamily: 'Roboto, sans-serif' },
                            }}
                            InputProps={{
                                style: { color: '#fff', fontFamily: 'Roboto, sans-serif' },
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            id="confirmPassword"
                            autoComplete="new-password"
                            InputLabelProps={{
                                style: { color: '#bbb', fontFamily: 'Roboto, sans-serif' },
                            }}
                            InputProps={{
                                style: { color: '#fff', fontFamily: 'Roboto, sans-serif' },
                            }}
                        />
                        <Button
                            type="submit"
                            onClick={handleRegister}
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor: '#af12b1',
                                fontFamily: 'Roboto, sans-serif',
                                '&:hover': {
                                    backgroundColor: '#d018d2',
                                },
                            }}
                        >
                            {loading ? <CircularProgress color="inherit" size={24} /> : 'Register'}
                        </Button>
                        {error && (
                            <Typography color="error" sx={{ mt: 2, fontFamily: 'Roboto, sans-serif' }}>
                                {error}
                            </Typography>
                        )}
                        <Box display="flex" justifyContent="space-between">
                            <Typography
                                onClick={handleLogin}
                                variant="body2"
                                sx={{
                                    color: '#af12b1',
                                    fontFamily: 'Roboto, sans-serif',
                                    cursor: 'pointer',
                                    "&:hover": {
                                        textDecoration: 'underline'
                                    }
                                }}
                            >
                                Already have an account? Login
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default RegisterPage;