// src/Components/Services/supabaseClient.js
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL; // Ensure this is defined in your .env
const supabaseKey = process.env.REACT_APP_ANON_KEY; // Ensure this is defined in your .env

// Initialize the Supabase client and export it
const supabase = createClient(supabaseUrl, supabaseKey);

export { supabase };
