import { useState } from 'react';
import {
  Container,
  CircularProgress,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import '@fontsource/roboto'; // Import Roboto font
import LoginImage from '../../img/login.svg'; // Replace with your illustration path
import { useNavigate } from 'react-router-dom';
import { supabase } from '../Services/SupabaseClient'; // Import the supabase client

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    // Basic form validation
    if (!email || !password) {
      setError("Please fill in both fields.");
      setLoading(false);
      return;
    }

    try {
      // Use Supabase to authenticate the user
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        throw error;
      }

      if (data.session) {
        // Show success toast
        navigate('/database');
        console.log("JWT Token:", data.session.access_token);
        // Store token in localStorage (or another secure storage method)
        localStorage.setItem("token", data.session.access_token);
        // Redirect or handle successful login
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  const handleRegister = () => {
    navigate('/register');
  };

  return (
    <Container
      maxWidth="md" // Wider container to accommodate two columns
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#121212', // Dark background color
        padding: 2,
        fontFamily: 'Roboto, sans-serif', // Apply font to the whole page
      }}
    >
      <Grid
        container
        spacing={4}
        sx={{
          backgroundColor: 'rgba(40, 40, 40, 0.9)', // Semi-transparent dark background
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        {/* Left Column: Illustration */}
        <Grid
          item
          xs={12} // Full width on small screens
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 3,
            borderRight: { md: '1px solid rgba(255, 255, 255, 0.1)' }, // Add a divider for larger screens
          }}
        >
          <Box
            component="img"
            src={LoginImage} // Replace with your illustration URL
            alt="Illustration"
            sx={{
              width: '100%',
              maxWidth: 300,
              borderRadius: 2,
              mb: 2,
            }}
          />
          {/* Copyright Section */}
          <Box
            sx={{
              marginTop: 'auto', // Push it to the bottom of the left column
              color: '#bbb', // Light gray color for copyright text
              fontSize: '0.875rem', // Smaller font size
              textAlign: 'center',
            }}
          >
            <Typography variant="body2" fontFamily="Roboto, sans-serif">
              &copy; {new Date().getFullYear()} TheCodewise.com. All rights reserved.
            </Typography>
          </Box>
        </Grid>

        {/* Right Column: Login Form */}
        <Grid
          item
          xs={12} // Full width on small screens
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 3,
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            sx={{ mb: 3, color: '#fff', fontFamily: 'Roboto, sans-serif' }}
          >
            Login
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              autoFocus
              InputLabelProps={{
                style: { color: '#bbb', fontFamily: 'Roboto, sans-serif' },
              }}
              InputProps={{
                style: { color: '#fff', fontFamily: 'Roboto, sans-serif' }, // White text for input
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              autoComplete="current-password"
              InputLabelProps={{
                style: { color: '#bbb', fontFamily: 'Roboto, sans-serif' },
              }}
              InputProps={{
                style: { color: '#fff', fontFamily: 'Roboto, sans-serif' }, // White text for input
              }}
            />
            <Button
              type="submit"
              onClick={handleLogin}
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: '#af12b1', // Primary button color
                fontFamily: 'Roboto, sans-serif', // Apply font
                '&:hover': {
                  backgroundColor: '#d018d2', // Darker on hover
                },
              }}
            >
              {loading ? <CircularProgress color="inherit" size={24} /> : 'Login'}
            </Button>
            {error && (
              <Typography variant="body2" color="error" sx={{
                mt: 2,
                mb: 2,
                fontFamily: 'Roboto, sans-serif'
              }}>
                {error}
              </Typography>
            )}
            <Box display="flex" justifyContent="space-between" sx={{ width: '100%' }}>
              <Typography
                onClick={handleForgotPassword}
                variant="body2"
                sx={{
                  color: '#af12b1',
                  fontFamily: 'Roboto, sans-serif',
                  cursor: 'pointer',
                  "&:hover": {
                    textDecoration: 'underline'
                  }
                }}
              >
                Forgot password?
              </Typography>
              <Typography
                onClick={handleRegister}
                variant="body2"
                sx={{
                  color: '#af12b1',
                  fontFamily: 'Roboto, sans-serif',
                  cursor: 'pointer',
                  "&:hover": {
                    textDecoration: 'underline'
                  }
                }}
              >
                {"Don't have an account? Sign Up"}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LoginPage;