// src/Components/PrivateRoute.jsx
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { supabase } from '../Components/Services/SupabaseClient'; // Import the initialized Supabase client

const PrivateRoute = ({ component: Component }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Use the correct method to get the session
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession(); // Correct method

      if (session) {
        setIsAuthenticated(true);  // If a session exists, user is authenticated
      } else {
        setIsAuthenticated(false); // No session, user is not authenticated
      }

      setLoading(false);  // Set loading state to false after checking
    };

    checkSession();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show a loading spinner or message
  }

  // If authenticated, render the component, otherwise redirect to login
  return isAuthenticated ? <Component /> : <Navigate to="/" />;
};

export default PrivateRoute;
