// components/Navbar.js

import React, { useState, useEffect, useRef } from 'react';
import { FaCog, FaFileAlt, FaUserPlus, FaSignOutAlt } from 'react-icons/fa'; // Importing icons
import { FaDatabase } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { supabase } from '../Services/SupabaseClient'; // Import the initialized Supabase client


const Navbar = ({ handleDatabaseSelect }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const dropdownRef = useRef(null); // Create a ref for the dropdown

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleGenerateDocument = () => {
        navigate('/download');
    };

    const handleSignup = () => {
        navigate('/signup');
    };

    const handleSignout = async () => {
        try {
            await supabase.auth.signOut(); // Sign out the current user from Supabase
            navigate('/'); // Redirect to home or login page after sign-out
        } catch (error) {
            console.error("Error signing out:", error.message); // Log any sign-out errors
        }
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <nav className="navbar bg-gray-800 text-white p-4 flex justify-between">
            <h1 className="text-2xl font-bold">Database</h1>
            <div className="relative" ref={dropdownRef}>
                <button onClick={toggleDropdown} className="text-white focus:outline-none">
                    <FaCog size={24} />
                </button>
                {isDropdownOpen && (
                    <ul className="absolute right-0 mt-2 bg-gray-800 text-white rounded-lg shadow-lg py-2 w-52">
                        <li className="px-4 py-2 hover:bg-gray-700 flex items-center">
                            <FaUserPlus className="mr-2" />
                            <button onClick={handleSignup} className="text-white w-full text-left font-bold">
                                Add Users
                            </button>
                        </li>
                        <li className="px-4 py-2 hover:bg-gray-700 flex items-center">
                            <FaDatabase className="mr-2" />
                            <button onClick={() => handleDatabaseSelect('products')}
                                className="text-white w-full text-left font-bold">
                                Products
                            </button>
                        </li>
                        <li className="px-4 py-2 hover:bg-gray-700 flex items-center">
                            <FaDatabase className="mr-2" />
                            <button onClick={() => handleDatabaseSelect('ingredients')}
                                className="text-white w-full text-left font-bold">
                                Ingredients
                            </button>
                        </li>

                        <li className="px-4 py-2 hover:bg-gray-700 flex items-center text-blue-500">
                            <FaFileAlt className="mr-2" />
                            <button onClick={handleGenerateDocument} className="w-full text-left font-bold">
                                Generate Document
                            </button>
                        </li>
                        <li className="px-4 py-2 hover:bg-gray-700 flex items-center text-red-500">
                            <FaSignOutAlt className="mr-2" />
                            <button onClick={handleSignout} className="w-full text-left font-bold">
                                Sign Out
                            </button>
                        </li>
                    </ul>
                )}
            </div>
        </nav>
    );
};

export default Navbar;