import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import UserLogin from "./Components/Services/UserLogin";
import UserSignup from "./Components/Services/Register";
import Download from "./Components/Download";
import PrivateRoute from "./Components/PrivateRoute";
import RegisterPage from "./Components/Services/Register";
import Forget from "./Components/Services/Forget";
import Database from "./Components/Database/Database";
import ProductDBForm from "./Components/Database/ProductDBForm";
import IngredientDBForm from "./Components/Database/IngredientForm/IngredientDBForm";
import Productitem from "./Components/Database/Productitem";
import IngredientItem from "./Components/Database/IngredientForm/IngredientItem";
import NotFoundPage from "./Components/Services/Notfound";
import ResetPassword from "./Components/Services/ResetPassword";
import { ThemeProvider } from "@mui/material/styles";
import darkTheme from "./Components/Hooks/theme"; // Assuming your theme is in a file named theme.js
import { CssBaseline } from "@mui/material";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<UserLogin />} />
          <Route path="/forgot-password" element={<Forget />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route
            path="/signup"
            element={
              <PrivateRoute component={UserSignup} requiredRole="ADMIN" />
            }
          />
          <Route
            path="/database"
            element={<PrivateRoute component={Database} requiredRole="ADMIN" />}
          />
          <Route
            path="/productdbform"
            element={
              <PrivateRoute component={ProductDBForm} requiredRole="ADMIN" />
            }
          />
          <Route
            path="/download"
            element={<PrivateRoute component={Download} requiredRole="USER" />}
          />
          <Route
            path="/ingredientdb"
            element={
              <PrivateRoute component={IngredientDBForm} requiredRole="ADMIN" />
            }
          />
          <Route
            path="/productitem/:product_id"
            element={
              <PrivateRoute component={Productitem} requiredRole="ADMIN" />
            }
          />
          <Route
            path="/ingredient/:ing_item_code"
            element={
              <PrivateRoute component={IngredientItem} requiredRole="ADMIN" />
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
      <ToastContainer
        theme="dark" // Change to 'light' if preferred
      />
    </ThemeProvider>
  );
}

export default App;
