import { useState } from 'react';
import {
    Container,
    Grid,
    Box,
    Typography,
    TextField,
    Button,
    CircularProgress,
} from '@mui/material';
import '@fontsource/roboto'; // Import Roboto font
import { useNavigate } from 'react-router-dom';
import ForgotPasswordImage from '../../img/forgot.svg'; // Replace with your illustration path
import { supabase } from '../Services/SupabaseClient'; // Supabase client
import { showSuccessToast, showErrorToast } from '../Hooks/Toastutils';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        if (!email) {
            setError('Please provide your email address.');
            setLoading(false);
            return;
        }

        try {
            const { error } = await supabase.auth.resetPasswordForEmail(email, {
                redirectTo: `${window.location.origin}/reset-password`,
            });
            if (error) {
                throw error;
            }

            showSuccessToast('Reset Link Sent');
            setTimeout(() => {
                navigate('/');  // Redirect to login page after success
            }, 2000);

        } catch (err) {
            setError(err.message);
            showErrorToast(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container
            maxWidth="md"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: '#121212',
                padding: 2,
                fontFamily: 'Roboto, sans-serif',
            }}
        >
            <Grid
                container
                spacing={4}
                sx={{
                    backgroundColor: 'rgba(40, 40, 40, 0.9)',
                    borderRadius: 2,
                    boxShadow: 3,
                }}
            >
                {/* Left Column: Illustration */}
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 3,
                        borderRight: { md: '1px solid rgba(255, 255, 255, 0.1)' },
                    }}
                >
                    <Box
                        component="img"
                        src={ForgotPasswordImage} // Replace with your illustration path
                        alt="Forgot Password Illustration"
                        sx={{
                            width: '100%',
                            maxWidth: 300,
                            borderRadius: 2,
                        }}
                    />
                    <Box
                        sx={{
                            color: '#bbb',
                            fontSize: '0.875rem',
                            textAlign: 'center',
                            marginTop: 2,
                        }}
                    >
                        <Typography variant="body2" fontFamily="Roboto, sans-serif">
                            &copy; {new Date().getFullYear()} TheCodewise.com. All rights reserved.
                        </Typography>
                    </Box>
                </Grid>

                {/* Right Column: Forgot Password Form */}
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 3,
                    }}
                >
                    <Typography
                        component="h1"
                        variant="h5"
                        sx={{ mb: 3, color: '#fff', fontFamily: 'Roboto, sans-serif' }}
                    >
                        Forgot Password
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 1, width: '100%' }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="email"
                            autoFocus
                            InputLabelProps={{
                                style: { color: '#bbb', fontFamily: 'Roboto, sans-serif' },
                            }}
                            InputProps={{
                                style: { color: '#fff', fontFamily: 'Roboto, sans-serif' },
                            }}
                        />
                        <Button
                            type="submit"
                            onClick={handleResetPassword}
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor: '#af12b1',
                                fontFamily: 'Roboto, sans-serif',
                                '&:hover': {
                                    backgroundColor: '#d018d2',
                                },
                            }}
                        >
                            {loading ? <CircularProgress color="inherit" size={24} /> : 'Send Reset Link'}
                        </Button>
                        {error && (
                            <Typography color="error" sx={{ mt: 2, fontFamily: 'Roboto, sans-serif' }}>
                                {error}
                            </Typography>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ForgotPassword;